import React, { Component } from 'react';
import { Helmet } from "react-helmet-async";
import { decode } from 'he';

class MetaHeader extends Component {
  render() {
    const { p } = this.props;
    return (
      <Helmet
        defaultTitle={decode(p.seo.title !== "" ? p.seo.title : p.title.rendered)}
        meta={[
          {"name": "description", "content": p.seo.metadesc !== "" ? p.seo.metadesc : p.excerpt.rendered },
          {"name": "og:title", content: p.seo['opengraph-title'] !== "" ? p.seo['opengraph-title'] : p.title.rendered },
          {"name": "og:description", content: p.seo['opengraph-description'] !== "" ? p.seo['opengraph-description'] : p.excerpt.raw || p.excerpt.rendered },
          {"name": "og:url", content: ``},
          {"name": "og:site_name", content: `Democratic Alliance`},
          {"name": "og:type", content: `business`},
          {"name": "og:image", content: p.seo['opengraph-image'] || null},
          {"name": "fb:app_id", content: '1262049647255981'},
          {"name": "twitter:card", content: 'summary_large_image'},
          {"name": "twitter:site", content: '@our_da'},
          {"name": "twitter:title", content: p.seo['twitter-title'] !== "" ? p.seo['twitter-title'] : p.title.rendered},
          {"name": "twitter:description", content: p.seo['twitter-description'] !== "" ? p.seo['twitter-description'] : p.excerpt.raw || p.excerpt.rendered},
          {"name": "twitter:image:src", content: p.seo['twitter-image'] !== "" ? p.seo['twitter-image'] : null},
        ]}
        htmlAttributes={{"lang": "en"}}
      />
    )
  }
}

export default MetaHeader;
