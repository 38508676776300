import React, { Fragment } from "react";
import { Copy, Hero, Heading } from "@website2018/da-dobsonville";

import { Helmet } from "react-helmet-async";
import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import { noComma } from "../../common/utils";
import FormBuilderContainer from "../Data/FormBuilderContainer";
import FormContainer from "../Data/FormContainer";
import { decode } from 'he';

const VolunteerTemplate = props => {
  const { page: p, noWhatsApp = false, history } = props;

  return [
    <Helmet
      defaultTitle={decode(p.seo.title !== "" ? p.seo.title : p.title.rendered)}
      meta={[
        {
          name: "description",
          content: p.seo.metadesc !== "" ? p.seo.metadesc : p.excerpt.rendered
        },
        {
          name: "og:title",
          content:
            p.seo["opengraph-title"] !== ""
              ? p.seo["opengraph-title"]
              : p.title.rendered
        },
        {
          name: "og:description",
          content:
            p.seo["opengraph-description"] !== ""
              ? p.seo["opengraph-description"]
              : p.excerpt.raw || p.excerpt.rendered
        },
        { name: "og:url", content: "" },
        { name: "og:site_name", content: "Democratic Alliance" },
        { name: "og:type", content: `business` },
        { name: "og:image", content: p.seo["opengraph-image"] || null },
        { name: "fb:app_id", content: "1262049647255981" },
        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:site", content: "@our_da" },
        {
          name: "twitter:title",
          content:
            p.seo["twitter-title"] !== ""
              ? p.seo["twitter-title"]
              : p.title.rendered
        },
        {
          name: "twitter:description",
          content:
            p.seo["twitter-description"] !== ""
              ? p.seo["twitter-description"]
              : p.excerpt.raw || p.excerpt.rendered
        },
        {
          name: "twitter:image:src",
          content: p.seo["twitter-image"] !== "" ? p.seo["twitter-image"] : null
        }
      ]}
      htmlAttributes={{ lang: "en" }}
    />,
    <Hero
      center
      small={false}
      mb={"none"}
      imageUrl={
        p.hero && p.hero.background_image ? p.hero.background_image.url : null
      }
      bgPosition={p.hero && p.hero.focus_point && noComma(p.hero.focus_point)}
      render={() => (
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <Heading
                size={"huge"}
                color={"white"}
                align={"left"}
                mb={"small"}
                html={
                  p.hero && p.hero.headline ? p.hero.headline : p.title.rendered
                }
              />
              <Copy color="white" intro html={p.content.rendered} />
            </div>
            <div className="col-md-5 ml-auto">
              <div style={{ padding: "2rem 1rem", background: "#eee" }}>
                <FormContainer
                  formId={p.slug}
                  render={form => (
                    <FormBuilderContainer
                      onSubmit={async values => {
                        await props.createEvent({
                          variables: {
                            input: {
                              form: form.id,
                              fields: {
                                ...values
                              }
                            }
                          }
                        });

                        history.push("/success");
                      }}
                      form={form}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    />
  ];
};

const ADD_SUBMISSION_MUTATION = gql`
  mutation submitForm($input: SubmissionInput!) {
    submitForm(input: $input)
  }
`;

const withEvent = graphql(ADD_SUBMISSION_MUTATION, { name: "createEvent" });

export default withEvent(VolunteerTemplate);
